import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
} from "react";
import styled, { ThemeContext } from "styled-components";
import useWindowWidth from "../../hooks/useWindowWidth";
import useWindowHeight from "../../hooks/useWindowHeight";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import InfoBlock from "./infoBlock";

const MAGIC_HEIGHT_CONSTANT = 8;

const VideoScroll = ({ infoBlocks = [] }) => {
  const url = typeof window !== "undefined" ? window.location.href : "";
  const container = useRef();
  const canvas = useRef();
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const [containerTop, setContainerTop] = useState(null);
  const [playbackHeight, setPlaybackHeight] = useState(null);
  const [context, setContext] = useState(null);
  const [imageArr, setImageArr] = useState([]);

  const imageCount = 150;
  const mobileOnlyEvery = 2;

  const theme = useContext(ThemeContext);
  const isMobile = windowWidth && windowWidth <= theme.maxMobileSize;

  useEffect(() => {
    if (container) {
      setContainerTop(container.current.getBoundingClientRect().top);
    }
  }, [container]);

  useEffect(() => {
    const id = setTimeout(() => {
      let images = [];
      for (
        let i = 1;
        i < imageCount;
        i = i + (isMobile ? mobileOnlyEvery : 1)
      ) {
        let filename = "Gl";
        if (i < 10) filename += "000";
        else if (i < 100) filename += "00";
        else if (i < 1000) filename += "0";
        filename += i + ".jpg";
        let img = new Image();
        img.src = url + "frames/" + filename;
        images.push(img);
      }
      setImageArr(images);
    }, 2000);
    return () => clearTimeout(id);
  }, [url, isMobile]);

  useEffect(() => {
    if (canvas) {
      let ctx = canvas.current.getContext("2d");
      ctx.canvas.width = window.innerWidth;
      ctx.canvas.height = window.innerHeight;
      ctx.fillStyle = "#000000";
      ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
      setContext(ctx);

      // Draw first image immediately
      let img = new Image();
      img.src = url + "frames/Gl0000.jpg";
      img.onload = () => {
        var scale = Math.min(
          canvas.current.width / img.width,
          canvas.current.height / img.height
        );
        var x = canvas.current.width / 2 - (img.width / 2) * scale;
        var y = canvas.current.height / 2 - (img.height / 2) * scale;
        // Minus 58 to account for the CTA buttons on mobile
        const yAdjust = isMobile ? 58 : 0;
        ctx.drawImage(
          img,
          x,
          y - yAdjust,
          img.width * scale,
          img.height * scale
        );
      };
    }
  }, [canvas, url, isMobile]);

  const drawImage = useCallback(
    (frameNum) => {
      let img = imageArr[frameNum];
      var scale = Math.min(
        canvas.current.width / img.width,
        canvas.current.height / img.height
      );
      var x = canvas.current.width / 2 - (img.width / 2) * scale;
      var y = canvas.current.height / 2 - (img.height / 2) * scale;
      // Minus 58 to account for the CTA buttons on mobile
      const yAdjust = isMobile ? 58 : 0;
      context.drawImage(
        img,
        x,
        y - yAdjust,
        img.width * scale,
        img.height * scale
      );
    },
    [context, imageArr, isMobile]
  );

  useScrollPosition(({ prevPos, currPos }) => {
    const top = currPos.y * -1;
    if (!containerTop) return;
    if (top > containerTop) {
      const frameNumber =
        (top - containerTop) / (MAGIC_HEIGHT_CONSTANT * windowHeight);
      let imgNum = Math.floor(frameNumber * imageArr.length);
      if (imgNum < imageArr.length) {
        drawImage(imgNum);
      }
    }
  });

  useEffect(() => {
    setPlaybackHeight(MAGIC_HEIGHT_CONSTANT * windowHeight);
  }, [windowHeight]);

  return (
    <Wrapper ref={container} height={playbackHeight}>
      <Inner>
        <Canvas ref={canvas} />
      </Inner>

      <InfoBlocks numBlocks={infoBlocks.length} timelineHeight={playbackHeight}>
        {infoBlocks.map((block, idx) => (
          <InfoBlock
            key={idx}
            dir={block.dir}
            year={block.year}
            blurb={block.blurb}
          />
        ))}
      </InfoBlocks>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colour.fullBlack};
  width: 100%;
  height: ${({ height }) =>
    height ? `${height}px` : `${MAGIC_HEIGHT_CONSTANT * 100}vh`};
  position: relative;
`;

const Inner = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 150;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100vh;
  object-fit: contain;
  object-position: center;
`;

const InfoBlocks = styled.div`
  > div {
    height: ${({ numBlocks }) =>
      `${0.85 * 100 * (MAGIC_HEIGHT_CONSTANT / numBlocks)}vh`};
  }
`;

export default VideoScroll;
