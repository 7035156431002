import { useState, useEffect } from "react";

const useWindowHeight = () => {
  const [height, setHeight] = useState(
    typeof window !== "undefined" && window.innerHeight
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return height;
};

export default useWindowHeight;
