import React, { useEffect } from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { Body } from "../common/typography";
import Year from "../common/year";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const InfoBlock = ({ dir, year, blurb }) => {
  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start({
        y: ["20%", "0%"],
        opacity: [0, 1],
        transition: { duration: 1, delay: 0 },
      });
    }
  }, [inView, controls]);

  return (
    <Wrapper ref={ref} inView={inView}>
      <Inner dir={dir} animate={controls}>
        <Year>{year}</Year>
        <Blurb>{blurb}</Blurb>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const Inner = styled(motion.div)`
  position: absolute;
  z-index: 160;
  ${({ dir }) => (dir === "left" ? "left: 7%;" : "right: 7%;")}
  background-image: ${({ theme }) => {
    const baseColor = transparentize(0.27, theme.colour.fullBlack);
    return `linear-gradient(to top, transparent 25%, ${baseColor} 50%, transparent 75%)`;
  }};
  padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.tiny}`};
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Blurb = styled(Body)`
  max-width: 280px;
`;

export default InfoBlock;
