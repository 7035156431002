import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useDarkMode } from "../ducks/colourModeSlice";
import { useUpdateHeaderActions } from "../ducks/headerActionsSlice";
import VideoScroll from "../components/video-scroll/videoScroll";
import ProgressMeter from "../components/progress-meter";
import SEO from "../components/common/seo";
import Year from "../components/common/year";
import CTAButton from "../components/common/cta-button";
import { H1, H3, Title, Body, Detail } from "../components/common/typography/";
import SealImage from "../components/images/Seal";
import BottleEmbossDetail from "../components/images/BottleEmbossDetail";
import BottleTop from "../components/images/BottleTop";
import Signature from "../components/images/Signature";
import pour from "../assets/images/Pour.jpg";
import featherBackground from "../assets/images/FeatherBackground.png";
import ScrollIcon from "../assets/svg/ScrollIcon.svg";
import { motion } from "framer-motion";

const IndexPage = () => {
  useDarkMode();
  useUpdateHeaderActions({
    // left: {
    //   text: "Enter to Win",
    //   to: "/enter",
    // },
    right: {
      text: "Log In",
      to: "/login",
    },
  });

  const infoBlocks = [
    {
      dir: "left",
      year: "MDCCCXXXIII",
      blurb:
        "From the moment George Connell acquired his license to distil whisky in 1833, Glengoyne has taken its time to do things the right way.",
    },
    {
      dir: "right",
      year: "MDCCCLXIX",
      blurb:
        "With the arrival of distillery manager Cochrane Cartwright, the process was slowed even further and sherry casks were introduced. The result is an incredible fruity and complex whisky produced the right way.",
    },
    {
      dir: "left",
      year: "MCMLXVIII",
      blurb:
        "One century later, a small number of hand selected oak casks were filled at Glengoyne Distillery and our sweet, fruity new make spirit was left to sleep quietly in the depths of our dunnage warehouses.",
    },
    {
      dir: "right",
      year: "MMXX",
      blurb:
        "Five decades passed until 2020, when those specially selected casks were married together to create a whisky worthy of becoming Glengoyne 50 Year Old Highland Single Malt.",
    },
  ];

  return (
    <Root>
      <SEO title="Home" />
      <Hero>
        <CTA>
          <AnimateWrapper
            animate={{ y: ["10%", "0%"], opacity: [0, 1] }}
            transition={{ duration: 1 }}
          >
            <H1>
              Savour the <i>oldest ever</i> Glengoyne, in good company
            </H1>
          </AnimateWrapper>
          <LinkWrapper>
            <CTAAnchor to="/#more">Learn More</CTAAnchor>
            {/* <CTALink to="/enter">Enter to Win</CTALink> */}
          </LinkWrapper>
        </CTA>
        <ScrollPrompt to="/#more">
          <ScrollText>Scroll</ScrollText>
          <ScrollIcon />
        </ScrollPrompt>
      </Hero>
      <TextPullout id="more">
        <YearBlack>Unhurried since 1833</YearBlack>
        <SealPositioner>
          <Blurb>
            In the stillness of our quiet glen, we know that patience is
            rewarded. As Scotland's slowest distilled malt, no stage of our
            whisky making process is rushed and everything is given as long as
            it needs.
          </Blurb>
          <SealWrapper>
            <SealImage />
          </SealWrapper>
        </SealPositioner>
        <Blurb>
          Fifty years in the making, this very special release - our oldest ever
          - is the pinnacle of that unhurried approach to our craft. And we
          believe now is the right time for it to be enjoyed.
        </Blurb>
        <Blurb>
          So, as we look ahead to a year of reunions, delayed celebrations,
          weddings, anniversaries, and unhurried time with those we love — we
          will be opening a rare decanter of Glengoyne 50 Year Old especially
          for you.
        </Blurb>
      </TextPullout>
      <VideoScroll infoBlocks={infoBlocks} />
      <FullWidthImage>
        <BottleEmbossDetail />
      </FullWidthImage>
      {/* <MeterDemo>
        <DemoHeading>
          Get together with friends or family to enter and win the special
          decanter
        </DemoHeading>
        <DemoBlurb>
          To enter and be in with a chance of winning this special release,
          you'll need a minimum of four people who you plan on sharing the
          whisky with to sign up through this ballot. The entry will become
          valid once all members have signed up.
        </DemoBlurb>
        <DemoBlurb>
          As part of your entry, let us know the special moment you'd like to
          enjoy together with this whisky, and we'll make it happen in 2021.
        </DemoBlurb>
        <Buttons>
          <CTAButton text="Enter to Win" to="/enter" spaced />
          <CTAButton text="Log In" to="/login" spaced />
        </Buttons>
        <ProgressMeter block={false} friendCount={2} />
      </MeterDemo> */}
      <Closing>
        <Year>MMXXI</Year>
        <Title>5 decades have passed...</Title>
        <ClosingBlurb>
          Here's to the perfect moment to savour in 2021. Slainté.
        </ClosingBlurb>
        <Signature />
      </Closing>
      <FullWidthImage>
        <BottleTop />
      </FullWidthImage>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Hero = styled.div`
  width: 100%;
  min-height: 100vh;

  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 65%
    ),
    url(${pour});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
  justify-items: start;
  align-items: end;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      url(${pour});
    grid-template-rows: 4fr 1fr;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
`;

const CTA = styled(motion.div)`
  max-width: 520px;
  margin: ${({ theme }) => theme.spacing.mega};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: 0 ${({ theme }) => theme.spacing.xl};
    padding-top: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    ${H1} {
      text-align: center;
    }
  }
`;

const LinkWrapper = styled.div`
  width: max-content;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: ${({ theme }) => theme.spacing.xl} auto;
  }
`;

const CTALink = styled(Link)`
  border-bottom: 1px solid ${({ theme }) => theme.colour.whiskyOrange};
  display: inline-flex;
  padding-bottom: ${({ theme }) => theme.spacing.tiny};
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colour.fullWhite};
  font-size: 16px;
  line-height: 20px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    width: 1px;
    background-color: ${({ theme }) => theme.colour.fullWhite};
    visibility: hidden;
    transition: all 0.3s ease-out 0s;
  }
  &:hover:after {
    width: 100%;
    visibility: visible;
    transform: scaleX(1);
  }
`;

const CTAAnchor = styled(AnchorLink)`
  border-bottom: 1px solid ${({ theme }) => theme.colour.whiskyOrange};
  display: inline-flex;
  padding-bottom: ${({ theme }) => theme.spacing.tiny};
  margin-right: ${({ theme }) => theme.spacing.lg};
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colour.fullWhite};
  font-size: 16px;
  line-height: 20px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    width: 1px;
    background-color: ${({ theme }) => theme.colour.fullWhite};
    visibility: hidden;
    transition: all 0.5s ease-in-out 0s;
  }
  &:hover:after {
    width: 100%;
    visibility: visible;
    transform: scaleX(1);
  }
`;

const ScrollPrompt = styled(AnchorLink)`
  text-decoration: none;
  justify-self: end;
  margin: ${({ theme }) => theme.spacing.xl};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin: 0;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ScrollText = styled(Detail)`
  color: ${({ theme }) => theme.colour.sigPeach};
  text-transform: uppercase;
`;

const TextPullout = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.colour.bgBeige};
  width: 100%;
  min-height: 550px;
  padding: ${({ theme }) => theme.spacing.mega} 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const YearBlack = styled(Year)`
  color: ${({ theme }) => theme.colour.fullBlack};
`;

const Blurb = styled(Title)`
  color: ${({ theme }) => theme.colour.fullBlack};
  text-align: center;
  max-width: 700px;
  margin: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  font-style: italic;
  font-weight: 400;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.md}px`}) {
    max-width: 290px;
  }
`;

const SealPositioner = styled.div`
  position: relative;
`;

const SealWrapper = styled.div`
  position: absolute;
  top: -80px;
  right: -200px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.lg}px`}) {
    display: none;
  }
`;

const FullWidthImage = styled.div`
  width: 100%;
`;

const MeterDemo = styled.div`
  position: relative;
  z-index: 200;
  width: 100%;
  margin: ${({ theme }) =>
    `${theme.spacing.mega} auto ${theme.spacing.xl} auto`};
  background-image: url(${featherBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    background: none;
  }
`;

const DemoHeading = styled(H3)`
  text-align: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${({ theme }) => theme.spacing.lg};
`;

const DemoBlurb = styled(Body)`
  text-align: center;
  max-width: 500px;
  margin: ${({ theme }) => theme.spacing.lg} auto;
  padding: 0 ${({ theme }) => theme.spacing.lg};
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  margin: auto;
`;

const Closing = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.xl};
  margin-top: ${({ theme }) => `${theme.spacing.mega}`};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

const ClosingBlurb = styled(Body)`
  max-width: 560px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const AnimateWrapper = styled(motion.div)``;

export default IndexPage;
