import styled from "styled-components";
import { Detail } from "./typography/";

const Year = styled(Detail)`
  border-bottom: 1px solid ${({ theme }) => theme.colour.whiskyOrange};
  display: inline-flex;
  padding-bottom: ${({ theme }) => theme.spacing.tiny};
  text-transform: uppercase;
`;

export default Year;
